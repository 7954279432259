html,
body {
    background-color: #000;
    margin: 0;
}

.main-container {
    background-color: #000;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    height: 100%;
    width: 100%;
}

.main-ui {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;

    flex: 0 1 auto;
    overflow: hidden;
    /* align-self: auto; */
}

#game-container {
    width: 100%;
}

#metamask-connect-container {
    padding: 5px;
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;  */
}

#metamask-button-container .connect-wallet {
    cursor: pointer;
}

#metamask-button-container .connect-wallet:hover {
    filter: brightness(110%);
}

#metamask-button-container .switch-network {
    cursor: pointer;
}

#metamask-button-container .switch-network:hover {
    filter: brightness(120%);
}

#metamask-button-container .wallet-connected:hover {
    cursor: not-allowed;
}

#metamask-info {
    font-family: '04B03REV', Arial, Helvetica, sans-serif;
    padding: 0 0 0 10;
    color: white;
}

.auth-container {
    font-family: '04B03REV';
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 1rem;
    padding: 10px;
}

.logout-container {
    display: flex;
    justify-content: flex-end;
}

.login-container {
    /* position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 auto;
    max-width: 100vw; */
    flex: 20;
    max-height: 90%;
}

.login-spinner {
    display: flex;
    justify-content: center;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.login-bg {
    height: 0;
}

@media screen and (min-height: 768px) {
    .login-bg {
        background-image: url('../../public/assets/backgrounds/portal-fantasy-logo-bg.png');
        background-repeat: no-repeat;
        background-color: #000;
        background-size: 450px;
        background-position: center;
        height: 300px;
    }
}

.login-widget {
    max-width: 500px;
    margin: 3rem auto;
    padding: 2rem;
    border-radius: 0.25rem;
    background-color: #212529;
}

.login-widget-mobile {
    max-width: 500px;
    padding: 1.5rem;
    margin: 0 auto;
    border-radius: 0.25rem;
    background-color: #212529;
}

.login-widget-w {
    max-width: 800px;
}

.login-widget-w2 {
    max-width: 900px;
}

.input-group label {
    font-family: '04B03REV';
    color: #f58303;
    line-height: 1.75rem;
}

.login-widget-mobile div > input {
    font-family: '04B03REV';
    font-size: 1rem;
    appearance: none;
    display: block;
    width: 100%;
    color: #333;
    background-color: white;
    border: 2px solid transparent;
    padding: 0.7rem;
    border-radius: 0.25rem;
    margin-bottom: 1px;
}

div > input {
    font-family: '04B03REV';
    font-size: 1rem;
    appearance: none;
    display: block;
    width: 100%;
    color: #333;
    background-color: white;
    border: 2px solid transparent;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 5px;
}

input:focus {
    outline: none;
    border: 2px solid #f58303;
    background-clip: padding-box;
}

input.input-error {
    border: 1px solid #ff3630;
}

input.input-error:focus {
    border: 1px solid #ff3630;
}

.auth-container button,
.login-form button {
    font-family: '04B03REV';
    background-color: #f58303;
    border: none;
    color: #fff;
    font-weight: bold;
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
}

.challange-form button {
    font-family: '04B03REV';
    background-color: #f58303;
    border: none;
    color: #fff;
    font-weight: bold;
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
}

.auth-container button:hover {
    filter: brightness(110%);
}

.login-container button,
.login-form button {
    padding: 1rem;
    margin-top: 20px;
}

.login-container .login-widget-mobile button {
    padding: 0.7rem;
    margin-top: 20px;
}

.login-widget > .login-text {
    font-family: '04B03REV';
    font-size: 1.2rem;
    color: white;
    padding-bottom: 20px;
    text-align: center;
}

.login-widget-mobile > .login-text {
    font-family: '04B03REV';
    font-size: 1.2rem;
    color: white;
    padding-bottom: 5px;
    text-align: center;
}

.login-text-danger {
    font-family: '04B03REV';
    font-size: 1rem;
    color: red;
    padding-bottom: 20px;
    text-align: center;
}

.login-text-warn {
    font-family: '04B03REV';
    font-size: 1.02rem;
    text-align: center;
}

.login-form .input-group {
    margin-bottom: 10px;
}

.challange-form .input-group {
    margin-bottom: 10px;
}

.logout-button {
    background-color: red !important;
    font-family: '04B03REV' !important;
    height: 40px;
    width: 80px !important;
}

.logout-email {
    padding: 10px 10px 0 0;
}

.error-message {
    font-size: 0.85rem;
    color: #ff3630;
    margin-top: 5px;
}

.fontsize1 {
    font-size: 1rem;
}

.animate-rotate-faster {
    -webkit-animation: spin var(--rotate-duration-faster) linear infinite;
    -moz-animation: spin var(--rotate-duration-faster) linear infinite;
    animation: spin 5s linear 0s infinite;
}

@font-face {
    font-family: '04B03REV';
    src: url(../../public/assets/fonts/04B03REV.woff);
}

@font-face {
    font-family: 'lana-pixel';
    src: url(../../public/assets/fonts/LanaPixel.ttf);
    font-weight: normal;
}

@font-face {
    font-family: 'Zpix';
    src: url(../../public/assets/fonts/zpix.woff2);
    font-weight: normal;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.outside-game-container {
    font-family: '04B03REV';
}

/* GAME */

#network-dropdown-menu img,
#network-dropdown-btn img,
#network-beta-dropdown-btn img,
#network-beta-avalanche-btn img,
#network-beta-polygon-btn img {
    width: 20px;
}

input[type='text']::placeholder {
    color: white;
    opacity: 0.5;
}

span {
    color: white;
    font-family: '04B03REV';
}

button.click:active {
    transform: translateY(2px);
}

.social-label {
    width: 100%;
    margin: 15px auto;
    text-align: center;
    display: block;
}

.social-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.social-container .social {
    margin: 0 auto;
}

.social-container .social:hover {
    opacity: 0.7;
}

.social-container .social:active {
    transform: translateY(4px);
}

.social-container .social > img {
    height: 68px;
    width: 68px;
}

/* STRIPE FORM */

.payment-form {
    background: #312e42;
    border-radius: 10px;
    padding: 30px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 380px;
    height: 360px;
    border: 6px groove #dbddeb;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    z-index: 11;
}

.payment-form .btn {
    font-family: '04B03REV';
    color: white;
    border-color: 1px solid #000;
    z-index: 11;
}

.payment-form .btn:active {
    font-family: '04B03REV';
    color: white;
    border-color: 1px solid #000;
}

.payment-form .btn.close {
    font-family: '04B03REV';
    color: white;
    position: absolute;
    right: -15;
    top: -18;
    border: 2px solid #fff !important;
}

.payment-form .btn.click {
    color: #fff;
    font-family: '04B03REV';
    position: absolute;
    bottom: -20px;
    left: 30%;
    border: 2px solid #fff !important;
}

.payment-form .overlay {
    position: absolute;
    top: -1000%;
    left: -1000%;
    height: 10000%;
    width: 10000%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

/* GAME MODE */

.cols {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.col {
    width: calc(25% - 2rem);
    margin: 1rem;
    cursor: pointer;
}

.container {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.front,
.back {
    background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-position: center;
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: center;
    min-height: 280px;
    height: auto;
    border-radius: 10px;
    color: #fff;
    font-size: 1.3rem;
}

.back {
    background: #cedce7;
    background: -webkit-linear-gradient(45deg, #cedce7 0%, #596a72 100%);
    background: -o-linear-gradient(45deg, #cedce7 0%, #596a72 100%);
    background: linear-gradient(45deg, #cedce7 0%, #596a72 100%);
}

.front:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    opacity: 0.5;
    background-color: #000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
}

.back:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    opacity: 0.6;
    background-color: #000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
}

.container:hover .front,
.container:hover .back {
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.inner {
    font-family: '04B03REV';
    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
    transform: translateY(-50%) translateZ(60px) scale(0.94);
    top: 50%;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 2rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
}

.container .back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.container .front {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.container:hover .back {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.container:hover .front {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.front .inner p {
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
}

.front .inner p:after {
    content: '';
    width: 4rem;
    height: 2px;
    position: absolute;
    background: #c6d4df;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.75rem;
}

.front .inner span {
    color: rgba(255, 255, 255, 0.7);
    font-family: '04B03REV';
    font-weight: 300;
}

@media screen and (max-width: 64rem) {
    .col {
        width: calc(33.333333% - 2rem);
    }
}

@media screen and (max-width: 48rem) {
    .col {
        width: calc(50% - 2rem);
    }
}

@media screen and (max-width: 32rem) {
    .col {
        width: 100%;
        margin: 0 0 2rem 0;
    }
}
