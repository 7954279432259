html, body {
  background-color: #000;
  margin: 0;
}

.main-container {
  background-color: #000;
  flex-flow: column wrap;
  place-content: stretch center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
}

.main-ui {
  flex-flow: row;
  flex: 0 auto;
  place-content: stretch space-between;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

#game-container {
  width: 100%;
}

#metamask-connect-container {
  padding: 5px;
}

#metamask-button-container .connect-wallet {
  cursor: pointer;
}

#metamask-button-container .connect-wallet:hover {
  filter: brightness(110%);
}

#metamask-button-container .switch-network {
  cursor: pointer;
}

#metamask-button-container .switch-network:hover {
  filter: brightness(120%);
}

#metamask-button-container .wallet-connected:hover {
  cursor: not-allowed;
}

#metamask-info {
  color: #fff;
  padding: 0 0 0 10px;
  font-family: "04B03REV", Arial, Helvetica, sans-serif;
}

.auth-container {
  color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  font-family: "04B03REV";
  font-size: 1rem;
  display: flex;
}

.logout-container {
  justify-content: flex-end;
  display: flex;
}

.login-container {
  flex: 20;
  max-height: 90%;
}

.login-spinner {
  justify-content: center;
  display: flex;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-bg {
  height: 0;
}

@media screen and (height >= 768px) {
  .login-bg {
    background-color: #000;
    background-image: url("portal-fantasy-logo-bg.13f3d3ef.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 450px;
    height: 300px;
  }
}

.login-widget {
  background-color: #212529;
  border-radius: .25rem;
  max-width: 500px;
  margin: 3rem auto;
  padding: 2rem;
}

.login-widget-mobile {
  background-color: #212529;
  border-radius: .25rem;
  max-width: 500px;
  margin: 0 auto;
  padding: 1.5rem;
}

.login-widget-w {
  max-width: 800px;
}

.login-widget-w2 {
  max-width: 900px;
}

.input-group label {
  color: #f58303;
  font-family: "04B03REV";
  line-height: 1.75rem;
}

.login-widget-mobile div > input {
  appearance: none;
  color: #333;
  background-color: #fff;
  border: 2px solid #0000;
  border-radius: .25rem;
  width: 100%;
  margin-bottom: 1px;
  padding: .7rem;
  font-family: "04B03REV";
  font-size: 1rem;
  display: block;
}

div > input {
  appearance: none;
  color: #333;
  background-color: #fff;
  border: 2px solid #0000;
  border-radius: .25rem;
  width: 100%;
  margin-bottom: 5px;
  padding: 1rem;
  font-family: "04B03REV";
  font-size: 1rem;
  display: block;
}

input:focus {
  background-clip: padding-box;
  border: 2px solid #f58303;
  outline: none;
}

input.input-error, input.input-error:focus {
  border: 1px solid #ff3630;
}

.auth-container button, .login-form button, .challange-form button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #f58303;
  border: none;
  width: 100%;
  font-family: "04B03REV";
  font-size: 1rem;
  font-weight: bold;
  display: block;
}

.auth-container button:hover {
  filter: brightness(110%);
}

.login-container button, .login-form button {
  margin-top: 20px;
  padding: 1rem;
}

.login-container .login-widget-mobile button {
  margin-top: 20px;
  padding: .7rem;
}

.login-widget > .login-text {
  color: #fff;
  text-align: center;
  padding-bottom: 20px;
  font-family: "04B03REV";
  font-size: 1.2rem;
}

.login-widget-mobile > .login-text {
  color: #fff;
  text-align: center;
  padding-bottom: 5px;
  font-family: "04B03REV";
  font-size: 1.2rem;
}

.login-text-danger {
  color: red;
  text-align: center;
  padding-bottom: 20px;
  font-family: "04B03REV";
  font-size: 1rem;
}

.login-text-warn {
  text-align: center;
  font-family: "04B03REV";
  font-size: 1.02rem;
}

.login-form .input-group, .challange-form .input-group {
  margin-bottom: 10px;
}

.logout-button {
  height: 40px;
  background-color: red !important;
  width: 80px !important;
  font-family: "04B03REV" !important;
}

.logout-email {
  padding: 10px 10px 0 0;
}

.error-message {
  color: #ff3630;
  margin-top: 5px;
  font-size: .85rem;
}

.fontsize1 {
  font-size: 1rem;
}

.animate-rotate-faster {
  -webkit-animation: spin var(--rotate-duration-faster) linear infinite;
  -moz-animation: spin var(--rotate-duration-faster) linear infinite;
  animation: 5s linear infinite spin;
}

@font-face {
  font-family: "04B03REV";
  src: url("04B03REV.536e49d9.woff");
}

@font-face {
  font-family: lana-pixel;
  src: url("LanaPixel.9b2f7604.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Zpix;
  src: url("zpix.b4e43e95.woff2");
  font-weight: normal;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.outside-game-container {
  font-family: "04B03REV";
}

#network-dropdown-menu img, #network-dropdown-btn img, #network-beta-dropdown-btn img, #network-beta-avalanche-btn img, #network-beta-polygon-btn img {
  width: 20px;
}

input[type="text"]::placeholder {
  color: #fff;
  opacity: .5;
}

span {
  color: #fff;
  font-family: "04B03REV";
}

button.click:active {
  transform: translateY(2px);
}

.social-label {
  text-align: center;
  width: 100%;
  margin: 15px auto;
  display: block;
}

.social-container {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.social-container .social {
  margin: 0 auto;
}

.social-container .social:hover {
  opacity: .7;
}

.social-container .social:active {
  transform: translateY(4px);
}

.social-container .social > img {
  width: 68px;
  height: 68px;
}

.payment-form {
  z-index: 11;
  background: #312e42;
  border: 6px groove #dbddeb;
  border-radius: 10px;
  width: 380px;
  height: 360px;
  margin: auto;
  padding: 30px;
  position: absolute;
  inset: 0;
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
}

.payment-form .btn {
  color: #fff;
  border-color: 1px solid #000;
  z-index: 11;
  font-family: "04B03REV";
}

.payment-form .btn:active {
  color: #fff;
  border-color: 1px solid #000;
  font-family: "04B03REV";
}

.payment-form .btn.close {
  color: #fff;
  font-family: "04B03REV";
  position: absolute;
  top: -18px;
  right: -15px;
  border: 2px solid #fff !important;
}

.payment-form .btn.click {
  color: #fff;
  font-family: "04B03REV";
  position: absolute;
  bottom: -20px;
  left: 30%;
  border: 2px solid #fff !important;
}

.payment-form .overlay {
  z-index: 0;
  background-color: #0009;
  width: 10000%;
  height: 10000%;
  position: absolute;
  top: -1000%;
  left: -1000%;
}

.cols {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.col {
  cursor: pointer;
  width: calc(25% - 2rem);
  margin: 1rem;
}

.container {
  transform-style: preserve-3d;
  perspective: 1000px;
}

.front, .back {
  -o-transition: transform .7s cubic-bezier(.4, .2, .2, 1);
  backface-visibility: hidden;
  text-align: center;
  color: #fff;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  height: auto;
  min-height: 280px;
  font-size: 1.3rem;
  transition: transform .7s cubic-bezier(.4, .2, .2, 1), -webkit-transform .7s cubic-bezier(.4, .2, .2, 1);
  box-shadow: 0 4px 8px #00000040;
}

.back {
  background: linear-gradient(45deg, #cedce7 0%, #596a72 100%);
}

.front:after {
  z-index: 1;
  content: "";
  opacity: .5;
  backface-visibility: hidden;
  background-color: #000;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.back:after {
  z-index: 1;
  content: "";
  opacity: .6;
  backface-visibility: hidden;
  background-color: #000;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.container:hover .front, .container:hover .back {
  -o-transition: transform .7s cubic-bezier(.4, .2, .2, 1);
  transition: transform .7s cubic-bezier(.4, .2, .2, 1), -webkit-transform .7s cubic-bezier(.4, .2, .2, 1);
}

.back {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.inner {
  box-sizing: border-box;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  outline: 1px solid #0000;
  width: 100%;
  padding: 2rem;
  font-family: "04B03REV";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%)translateZ(60px)scale(.94);
}

.container .back {
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.container .front, .container:hover .back {
  transform-style: preserve-3d;
  transform: rotateY(0);
}

.container:hover .front {
  transform-style: preserve-3d;
  transform: rotateY(-180deg);
}

.front .inner p {
  margin-bottom: 2rem;
  font-size: 2rem;
  position: relative;
}

.front .inner p:after {
  content: "";
  background: #c6d4df;
  width: 4rem;
  height: 2px;
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: -.75rem;
  left: 0;
  right: 0;
}

.front .inner span {
  color: #ffffffb3;
  font-family: "04B03REV";
  font-weight: 300;
}

@media screen and (width <= 64rem) {
  .col {
    width: calc(33.3333% - 2rem);
  }
}

@media screen and (width <= 48rem) {
  .col {
    width: calc(50% - 2rem);
  }
}

@media screen and (width <= 32rem) {
  .col {
    width: 100%;
    margin: 0 0 2rem;
  }
}
/*# sourceMappingURL=index.76e0bdda.css.map */
